<template>
    <div class="section section-shaped section-zero min-vh-100 d-flex justify-content-center align-items-center">
        <div class="row justify-content-center container">
            <div class="col-12 col-lg-6 container-main shadow p-0" v-if="type_active !== 'not-have-permission'">
                <div
                    class="modal-header border-0"
                    v-if="type_active !== 'remember' && type_active !== 'login-token' && type_active !== 'login-ba'"
                >
                    <ul class="nav">
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ active: type_active === 'login' }"
                                href="#"
                                @click.prevent="changeType('login')"
                            >{{ $t('ct-sign-in') }}</a
                            >
                        </li>
                        <li class="nav-item" v-if="!config.hide_register_btn">
                            <a
                                class="nav-link"
                                :class="{ active: type_active === 'register' }"
                                href="#"
                                @click.prevent="changeType('register')"
                            >{{ $t('ct-register') }}</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="tab-content modal-body border-0">
                    <div class="tab-pane fade" :class="{ 'show active': type_active === 'register' }">
                        <register
                            v-if="type_active === 'register'"
                            :areas_register="areas_register"
                            @change="changeType"
                        ></register>
                    </div>

                    <div class="tab-pane fade" :class="{ 'show active': type_active === 'login' }">
                        <login v-if="type_active === 'login'" @change="changeType"></login>
                        <div>
                            <a class="text-forgot-password" href="#" @click.prevent="changeType('remember')">{{
                                    $t('ct-forgot-password')
                                }}</a>
                        </div>
                    </div>

                    <div class="tab-pane fade" :class="{ 'show active': type_active === 'remember' }">
                        <span class="title-forgot-password">{{ $t('ct-forgot-password') }}</span>
                        <remember-password @change="changeType"></remember-password>

                        <div class="mx-5 mt-2 mb-4 d-flex">
                            <div class="text-center flex-grow-1">
                                <template v-if="!config.hide_register_btn">
                                    <a href="#" @click.prevent="changeType('register')">{{ $t('ct-register') }}</a>
                                    <span class="mx-1">|</span>
                                </template>
                                <a href="#" @click.prevent="changeType('login')">{{ $t('ct-sign-in') }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" :class="{ 'show active': type_active === 'login-token' }">
                        <span class="title-forgot-password">{{ $t('ct-access-with-token') }}</span>
                        <login-token @change="changeType"></login-token>
                    </div>

                    <div class="tab-pane fade" :class="{ 'show active': type_active === 'login-ba' }">
                        <span class="title-forgot-password">{{ $t('ct-access-ba-credentials') }}</span>
                        <login-builderall @change="changeType"></login-builderall>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6" v-else-if="type_active === 'not-have-permission'">
                <not-have-permission></not-have-permission>
            </div>
        </div>
    </div>
</template>

<script>
import {mixinRestrictAreaConfig, mixinRestrictAreaForm} from './../../../../../js/mixins/restrictAreaForm';

export default {
    name: 'TwoTabsPopUp',
    mixins: [mixinRestrictAreaConfig, mixinRestrictAreaForm],
};
</script>

<style scoped>
</style>
